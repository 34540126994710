import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as dispsConceptos from '../../store/slices/situacion';
import {convertFecha} from '../../utils/convertFechas';
import {useForm} from '../../hooks/useConcepto';
import Cookies from 'universal-cookie';
import { urlApi } from '../../utils/api';
const cookies = new Cookies(); 
const axios = require('axios');


export default function () {
 
  useEffect(() => {  
    getConceptos();

  },[]);

  const [bruto, setBruto] = useState(0);
  const [descuentos, setDescuento] = useState(0);
  const [bolsillo, setBolsillo] = useState(0);  


  const {result} = useSelector((state) => state.body);
  const {conceptosList}  = useSelector((state) => state.conceptos);
  const {nivel}  = useSelector((state) => state.body);
  const dispatch = useDispatch();
  


  //hooks locales
    const { 
        onInputChange,   
        setloading,
        loading,    
        setmsjSituacion,
        anio,
        mes,
        conceptoId
    } = useForm({
        anio       : new Date().getFullYear(),
        mes        : (new Date().getMonth()+1)-2,  
        conceptoId : '*' 
    });

  const getConceptos = () => {

    
    setloading(true);
    let comodin = '';
    if(conceptoId==''){comodin='*'}

    dispatch(dispsConceptos.set_resetConcepto());
  
    axios.get(`${urlApi}/conceptos/${result[0].idAgente}/${anio}/${mes}/${conceptoId}${comodin}/${cookies.get('dataDni')}`)
      
      .then(resConceptos=> {
       
      
        let listConceptosTemp=[];

        let bruto = 0;
        let descuentos = 0;
        let bolsillo = 0;


        resConceptos.data.map(concepto => {
          bruto = bruto + concepto.haber;
          descuentos = descuentos + concepto.descuento;
          listConceptosTemp.push(concepto);
        })
            
        dispatch(dispsConceptos.set_conceptoList(listConceptosTemp));

        bolsillo = bruto - descuentos;

        setBruto(bruto.toFixed(2));
        setDescuento(descuentos.toFixed(2));
        setBolsillo(bolsillo.toFixed(2));

        //dispatch(conceptosSlice.actions.set_situacion(listSituacionTemp.reverse()));       
        setloading(false);
        //dispatch(situacionSlice.actions.set_editaSituacion(false));
            
      })   
      .catch(function (error) {
        setmsjSituacion('ERROR!: '+error)  
      });
  }  


  const fLoading = ()=>{
    return( 
      <div className="spinner-border text-info" role="status" style={{'marginTop':15}}>
        <span className="visually-hidden">Loading...</span>
      </div>
    )
  }

  return (
    <>
      <div className="row mt-2">

        <div className="col-md-4">
          <div className="input-group input-group-sm">
            <span className="input-group-text" id="inputGroup-sizing-sm">Año</span>
            <input 
              type="number" 
              name="anio"
              className="form-control" 
              aria-label="Sizing example input" 
              aria-describedby="inputGroup-sizing-sm"
              value={anio}
              onChange={onInputChange}
            />
          </div> 
        </div>

        <div className="col-md-4">
          <div className="input-group input-group-sm">
            <span className="input-group-text" id="inputGroup-sizing-sm">Mes</span>
            <input 
              type="number" 
              name="mes"
              className="form-control" 
              aria-label="Sizing example input" 
              aria-describedby="inputGroup-sizing-sm"
              value={mes}
              onChange={onInputChange}
            />
           </div> 
        </div>

        <div className="col-md-4">
          <div className="input-group input-group-sm">
            <span className="input-group-text" id="inputGroup-sizing-sm">id-Concepto</span>
            <input 
              type="number" 
              name="conceptoId"
              className="form-control" 
              aria-label="Sizing example input" 
              aria-describedby="inputGroup-sizing-sm"
              concepto={conceptoId}
              onChange={onInputChange}
            />
           </div> 
        </div>
          
        <div className="row p-1">
          <div className="col-md-12 p-2">
            <div>
              <button      
                className="btn btn-sm shadow mb-2 rounded" 
                onClick={()=>{getConceptos()}}
                style={{'backgroundColor':'#F3CDA9','marginRight':'2px'}}
              >
                Ver
              </button>                        
            </div>
          </div>
        </div>
      </div>

      <div className="row p-1 border-bottom">
        <div className="col-md-12">
          <div className="p-1">Conceptos:</div>
          <table className="table table-sm table-striped table-success table-bordered">
            <thead>

              <tr>
                <th scope="col">legajo</th>
                <th scope="col">mes</th>
                <th scope="col">dif</th>
                <th scope="col">año</th>
                <th scope="col">codigo</th>
                <th scope="col">detalle</th>
                <th scope="col">cantidad</th>
                <th scope="col">haber</th>
                <th scope="col">descuento</th>
                <th scope="col">ccm</th>    

              </tr>
            </thead>
            
            <tbody>
              {
                loading
                ?
                  fLoading()
                :
                  conceptosList.map(concepto => (
                    <tr key={concepto.legajo}>
                      <td>{concepto.legajo}</td>
                      <td>{concepto.mes}</td>
                      <td>{concepto.dif}</td>
                      <td>{concepto.aa}</td>
                      <td>{concepto.cod}</td>
                      <td>{concepto.detalle}</td>
                      <td>{concepto.cant}</td>
                      <td>{concepto.haber}</td>
                      <td>{concepto.descuento}</td>
                      <td>{concepto.ccm}</td>    
                    </tr>
                  ))
              }
            </tbody>
            <tbody>
              
              {
                loading
                ?
                  fLoading()
                :
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>${bruto}</td>
                    <td>${descuentos}</td>
                    <td></td>    
                  </tr>    
              }
            </tbody>
          
          </table>
        </div>
      </div>

      <div className="row p-1 border-bottom">
        <div className="col-md-12">
          <div className="p-1">Resumen:</div>
          <table className="table table-sm table-striped table-success table-bordered">
            <thead>

              <tr>
                <th scope="col">Haber Bruto</th>
                <th scope="col">Descuentos</th>
                <th scope="col">Bolsillo</th>
              </tr>
            </thead>
    
            <tbody>
              
                 <tr>
                    <td>${bruto}</td>
                    <td>${descuentos}</td>
                    <td>${bolsillo}</td>    
                  </tr>
                
              
            </tbody>
          </table>
        </div>
      </div>


    </>
  )
}