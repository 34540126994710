import React , { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Cookies from 'universal-cookie';
import { urlApi } from "../utils/api";
const axios = require('axios');
const cookies = new Cookies(); 

export default function (){

    const [ listInforme, setListInforme ] = useState( [] );
    const [ totalHaberes, setTotalHaberes] = useState(0);
    const [ cantidad, setCantidad] = useState(0);
    const [ loading, setLoading] = useState(false);

    const submitForm = async (valores)=>{
  
      setLoading(true);
       axios.get(`${urlApi}/informe/${valores.secretaria}/${valores.anio}/${valores.mes}/${valores.codigo}/${valores.sector}/${cookies.get('dataDni')}`)
        /*
        await axios({
          method: 'get',
          url: `${urlApi}/informe/${valores.secretaria}/${valores.anio}/${valores.mes}/${valores.codigo}/${valores.sector}`,
          //params:{dni:cookies.get('dataDni')},
        })
        */
      .then(function (result) {
        if(result.data.length>0){  
          setListInforme(result.data[0]);
          setTotalHaberes(result.data[1].total);
          setCantidad(result.data[1].cantidad)
        }else{
          setListInforme([{
            'legajo':'Sin Resultado',
            'mes':'Sin Resultados',
            'dif':'Sin Resultados',
            'aa':'Sin Resultados',
            'cod':'Sin Resultado',
            'detalle':'Sin Resultados',
            'cant':'Sin Resultado',
            'haber':'0',
            'descuento':'Sin Resultado',
            'ccm':'Sin Resultado',
            'ayp':'Sin Resultado'
          }]);
          setTotalHaberes(0);
          setCantidad(0)
        }
        setLoading(false);
        
      })
      .catch(function (error) {
        alert('ERROR2: ',error)  
      });
    }

    const fLoading = ()=>{
      return( 
        <div className="spinner-border text-info" role="status" style={{'marginTop':15}}>
          <span className="visually-hidden">Loading...</span>
        </div>
      )
    }

      return (
        <>
          <Formik
            initialValues={{
              anio:'2022',
              mes:'9'       
            }}
            validate={(valores) => {
              let errores = {};
              return errores;
            }}
            onSubmit={(valores) => {submitForm(valores);}}
          >

          {( {errors} ) => (
            <Form>
           
                <div className="row">
                 
                  <div className="col-md-12 text-center border-bottom border-top p-3">
                        
                        <div className='row mt-2'>
                          <div className="col-md-2">
                              <div 
                                className="input-group input-group-sm mb-3"
                              >
                                <label className="input-group-text" htmlFor="inputGroupSelect01">Secretaria</label>
                                <Field 
                                  className="form-select"
                                  as="select"
                                  name="secretaria"
                                  id="secretaria"  
                                >
                                  <option defaultValue="">Elegir...</option>
                                  <option value="S.G.EyC.G">S.G.EyC.G</option>
                                  <option value="S.A.PyD.S">S.A.PyD.S</option>
                                  <option value="S.H.">S.H.</option>
                                  <option value="S.D.SyF">S.D.SyF</option>
                                  <option value="S.P.OyS.P">S.P.OyS.P</option>
                                  <option value="S.T.C.DyC">S.T.C.DyC</option>
                                  
                                </Field>
                              </div>
                          </div>

                           <div className="col-md-2">
                              <div 
                                className="input-group input-group-sm mb-3"
                              >
                                <label className="input-group-text" htmlFor="inputGroupSelect01">Año</label>
                                <Field 
                                  className="form-select"
                                  as="select"
                                  name="anio"
                                  id="anio" 
                                >
                                  <option defaultValue="">Elegir...</option>
                                  <option value="2020">2020</option>
                                  <option value="2021">2021</option>
                                  <option value="2022">2022</option>
                                  <option value="2023">2023</option>
                                  <option value="2024">2024</option>
                                  <option value="2025">2025</option> 
                                </Field>
                              </div>
                          </div>

                           <div className="col-md-2">
                              <div 
                                className="input-group input-group-sm mb-3"
                              >
                                <label className="input-group-text" htmlFor="inputGroupSelect01">Mes</label>
                                <Field 
                                  className="form-select"
                                  as="select"
                                  name="mes"
                                  id="mes" 
                                >
                                  <option defaultValue="">Elegir...</option>
                                  <option value="1">Enero</option>
                                  <option value="2">Febrero</option>
                                  <option value="3">Marzo</option>
                                  <option value="4">Abril</option>
                                  <option value="5">Mayo</option>
                                  <option value="6">Junio</option>
                                  <option value="7">Julio</option>
                                  <option value="8">Agosto</option>
                                  <option value="9">Septiembre</option>
                                  <option value="10">Octubre</option>
                                  <option value="11">Noviembre</option>
                                  <option value="12">Diciembre</option>

                                </Field>
                              </div>
                          </div>

                          <div className="col-md-3">
                              <div 
                                className="input-group input-group-sm mb-3"
                              >
                                <label className="input-group-text" htmlFor="inputGroupSelect01">Codigo</label>
                                <Field 
                                  className="form-select"
                                  as="select"
                                  name="codigo"
                                  id="codigo" 
                                >
                                  <option defaultValue="155">Elegir...</option>
                                  <option value="138">Tareas Extraordinarias</option>
                                  <option value="155">Dedicacion Funcional</option>
                                  <option value="152">Dedicacion Exlusiva</option>
                                  <option value="115">Horas Extras Normales</option>
                                  <option value="111">Horas Extras al 50%</option>
                                  <option value="112">Horas Extras al 100%</option>
                                  <option value="156">Fallas de Caja</option>
                                  <option value="107">Bonif. Ord. 8480/22</option>
                                  <option value="157">Adicional por Subrogancia</option>
                                  <option value="150">Tareas Riesgosas y/o Insalubre</option>
                                  <option value="105">Adicional Sereno</option>  
                                  <option value="165">Tareas de Inspección</option>  
                                  
                                </Field>
                              </div>
                          </div>

                          <div className="col-md-3">
                            <div className="input-group input-group-sm mb-3">
                              <span className="input-group-text" id="inputGroup-sizing-sm">Sector</span>
                              <Field 
                                type="text"
                                name="sector"
                                id="sector"
                                className="form-control" 
                                aria-label="Sizing example input" 
                                aria-describedby="inputGroup-sizing-sm"
                              />
                            </div> 
                          </div>

                          <div className="row p-1 border-bottom">  
                            <div className="col-md-12 p-2">
                              
                              <button 
                                type="submit"
                                className="btn btn-sm shadow mb-5 rounded" 
                                style={{'backgroundColor':'#F3CDA9'}}
                              >
                                Ver Informe
                              </button>
                              
                            </div>
                          </div>  

                        </div>

               

                      </div>
                 
                </div>
 
            </Form>
          )}
          </Formik>
          <div className='row mt-2'>
                          <div className="col-md-12 p-2">

                            <table className="table table-sm table-striped table-success table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">Legajo</th>
                                  <th scope="col">Mes</th>
                                  <th scope="col">Año</th>
                                  <th scope="col">Codigo</th>
                                  <th scope="col">Detalle</th>
                                  <th scope="col">Haber</th>
                                  <th scope="col">AyP</th>
                                </tr>
                              </thead>
      
                              <tbody>
                                {
                                  loading 
                                  ?
                                  fLoading()
                                  :
                                  listInforme.map(item => (
                                    <tr key={item.legajo}>
                                      <td>{item.legajo}</td>  
                                      <td>{item.mes}</td>
                                      <td>{item.aa}</td>
                                      <td>{item.cod}</td>
                                      <td>{item.detalle}</td>
                                      <td>${item.haber}</td>
                                      <td>{item.ayp}</td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                              <tbody>
                               
                                <tr>
                                  <td style={{'color': 'red !important;'}}>Total Agentes:</td>  
                                  <td>{cantidad}</td>
                                  <td></td>
                                  <td></td>
                                  <td>Total Haberes:</td>
                                  <td>${totalHaberes.toFixed(2)}</td>
                                  <td></td>
                                </tr>
                                  
                                
                              </tbody>
                            </table>

                          </div>  
                        </div>  
        </>
      )
}