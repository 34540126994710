import React from 'react';
import EscudoMuni from "../assets/escudoMuni.webp";
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

const cookies = new Cookies(); 

export default function Navbar() {

    const navigate = useNavigate();

    const salirDelSistema=()=>{
        cookies.remove('dataDni');
        cookies.remove('dataLegajo');
        cookies.remove('dataApellido');
        cookies.remove('dataNombre');
        cookies.remove('dataPver');
        cookies.remove('dataPeditar');
        cookies.remove('dataPaltas');       
        //window.location.reload(false);
        navigate("/")
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light shadow p-3 mb-5 rounded" style={{'backgroundColor':'#F3CDA9'}}>
            <div className="container-fluid">
                <img 
                    src={EscudoMuni} 
                    alt="Municipalidad de Rawson" 
                    width="35" 
                    height="35" 
                    className="d-inline-block align-text-top" 
                    style={{'marginRight':'15px'}}
                />    
                <div style={{'marginRight':'2rem'}}>
                        Departamento de Personal
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  
                    <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Usuario
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a className="dropdown-item" onClick={()=>salirDelSistema()}>Salir del Sistema</a></li>         
                    </ul>
                    </li>
                 
                </ul>
              
                </div>
            </div>
        </nav>
       
    )
   
}
