import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { suplementosSlice } from '../../store/slices/situacion';
import { useForm } from '../../hooks/useSuplementos';
import {convertFecha} from '../../utils/convertFechas';
import { urlApi } from '../../utils/api';
const cookies = new Cookies(); 
const axios = require('axios');

export default function () {
 
  useEffect(() => {  getSuplementos(); },[]);
  const {result} = useSelector((state) => state.body);
  const {editaSuplemento, suplementosList, togleIcon}  = useSelector((state) => state.suplementos);
  const {nivel}  = useSelector((state) => state.body);
  const dispatch = useDispatch();

  //hooks locales
  const { 
      setidSuplemento,
      idSuplemento,
      setmsjSuplemento,
      msjSuplemento,
      setmsjOkSuplemento,
      msjOkSuplemento,
      setloading,
      loading,
      setFormState,    
      onInputChange,
      
      suplemento,
      porcentaje,
      resAltaSupl,
      fechaAltaSupl,
      resBajaSupl,
      fechaBajaSupl

  } = useForm({
    suplemento      : '',
    porcentaje      : '',  
    resAltaSupl     : 0,
    fechaAltaSupl   : '',
    resBajaSupl     : 0,
    fechaBajaSupl   : '',
  });

  const getSuplementos = () => {
    setloading(true);

    axios.get(`${urlApi}/suplementoList/${result[0].idAgente}`,{params:{dni:cookies.get('dataDni')}})
      .then(resSuplementoList => {
        let fechaAltaSuplStr='';
        let fechaBajaSuplStr='';
        let listSuplementoTemp=[];

        resSuplementoList.data.map(suplemento => {
          (suplemento.fechaAlta.includes('1969')||suplemento.fechaAlta.includes('1970'))?fechaAltaSuplStr='':fechaAltaSuplStr=suplemento.fechaAlta;
          (suplemento.fechaBaja.includes('1969')||suplemento.fechaBaja.includes('1970'))?fechaBajaSuplStr='':fechaBajaSuplStr=suplemento.fechaBaja;

          var options = { year:'numeric', month:'long', day:'numeric', timeZone:'America/Argentina/Buenos_Aires'};
         
          if(fechaAltaSuplStr!==''){
            let fechaAltaFormat = new Date(fechaAltaSuplStr);
            fechaAltaFormat.setMinutes(fechaAltaFormat.getMinutes() + fechaAltaFormat.getTimezoneOffset())//compensacion diferencia de dia
            fechaAltaSuplStr=fechaAltaFormat.toLocaleDateString('es-AR',options);  
          }

          if(fechaBajaSuplStr!==''){
            let fechaBajaFormat = new Date(fechaBajaSuplStr);
            fechaBajaFormat.setMinutes(fechaBajaFormat.getMinutes() + fechaBajaFormat.getTimezoneOffset())//compensacion diferencia de dia
            fechaBajaSuplStr=fechaBajaFormat.toLocaleDateString('es-AR',options);
          }
          suplemento.fechaAlta=fechaAltaSuplStr;
          suplemento.fechaBaja=fechaBajaSuplStr;
          listSuplementoTemp.push(suplemento);
        })
        dispatch(suplementosSlice.actions.set_suplementosList( listSuplementoTemp.reverse() ));       
        setloading(false);
        
        setFormState({
          suplemento    : '',
          porcentaje    : '',
          resAltaSupl   : 0,
          fechaAltaSupl : '',
          resBajaSupl   : 0,
          fechaBajaSupl : ''
        });
        
        dispatch(suplementosSlice.actions.set_editaSuplemento(false));
      })   
      .catch(function (error) {
        setmsjSuplemento('ERROR!: '+error)  
      });
  }

  const submitFormSuplemento = ()=>{

      setmsjSuplemento('');

      if(suplemento===''){
        setmsjSuplemento('Debe elegir un suplemento');
      }else{
        setloading(true);
        let fechaAltaSuplFormat = new Date(fechaAltaSupl+'T00:00:00');
        let fechaBajaSuplFormat = new Date(fechaBajaSupl+'T00:00:00');

        axios({
          method: 'post',
          url: urlApi+'/suplemento',
          params:{dni:cookies.get('dataDni')},
          data: {
            "idSuplemento"   : 0,
            "suplemento"     : suplemento,
            "porcentaje"     : porcentaje, 
            "resolucionAlta" : resAltaSupl, 
            "fechaAlta"      : fechaAltaSuplFormat, 
            "fechaBaja"      : fechaBajaSuplFormat, 
            "resolucionBaja" : resBajaSupl, 
            "idAgente"       : result[0].idAgente 
          }
        })
        .then(function () {
          getSuplementos();
        })
        .catch(function (error) {
          console.log('-->>',error);
        });
      }
    
  }

  const selectSuplemento = (suplemento,accion)=>{

    setmsjSuplemento('');

    if(accion==='editar'){
      dispatch(suplementosSlice.actions.set_editaSuplemento(true));
      setidSuplemento(parseInt(suplemento.idSuplemento));
    }else if(accion==='ver'){
      dispatch(suplementosSlice.actions.set_editaSuplemento(false));
    }


    console.log('suplemento->',suplemento.suplemento)

    setFormState({
      suplemento    : suplemento.suplemento.toString(),
      porcentaje    : suplemento.porcentaje,
      resAltaSupl   : suplemento.resolucionAlta,
      fechaAltaSupl : convertFecha(suplemento.fechaAlta),
      resBajaSupl   : suplemento.resolucionBaja,
      fechaBajaSupl : convertFecha(suplemento.fechaBaja)
    })
   
  }

  const borrarSuplemento = (suplemento) =>{
    setmsjSuplemento('');

    if (window.confirm('Confirma borrar el suplemento N° '+suplemento.idSuplemento+' '+suplemento.suplemento+'?')) {

      setloading(true);

      axios({
        method: 'delete',
        url: urlApi+'/suplemento/'+suplemento.idSuplemento,
        params:{dni:cookies.get('dataDni')},
      })
      .then(function () {
        getSuplementos();
        setmsjOkSuplemento("El Suplemento N° "+suplemento.idSuplemento+" "+suplemento.suplemento+" se eliminó correctamente");
      })
      .catch(function (error) {
        console.log('-->>',error);
      });
    }
  }

  const terminarEdicionSuplemento = () =>{
    setFormState({
      suplemento      : '',
      porcentaje      : '',
      resAltaSupl     : 0,
      fechaAltaSupl   : '',
      resBajaSupl     : 0,
      fechaBajaSupl   : '',
      editaSuplemento : false
    })  
  }

  const guardarEdicionSuplemento = ()=>{
    setloading(true);
    let fechaAltaSuplFormat = new Date(fechaAltaSupl+'T00:00:00');
    let fechaBajaSuplFormat = new Date(fechaBajaSupl+'T00:00:00');

    axios({
      method: 'put',
      url: urlApi+'/suplemento/'+idSuplemento,
      params:{dni:cookies.get('dataDni')},
      data: {
        "suplemento"     : suplemento,
        "porcentaje"     : porcentaje, 
        "resolucionAlta" : resAltaSupl, 
        "fechaAlta"      : fechaAltaSuplFormat, 
        "fechaBaja"      : fechaBajaSuplFormat, 
        "resolucionBaja" : resBajaSupl, 
        "idAgente"       : result[0].idAgente 
      }
    })
    .then(function () {
      getSuplementos();
      setmsjOkSuplemento("El suplemento N° "+idSuplemento+" se editó correctamente");
    })
    .catch(function (error) {
      console.log('-->>',error);
    });

  }

  const fLoading = ()=>{
    return( 
      <div className="spinner-border text-info" role="status" style={{'marginTop':15}}>
        <span className="visually-hidden">Loading...</span>
      </div>
    )
  }

  return (
    <>
      {editaSuplemento && <p style={{'color':'red'}}>Vas a editar el suplemento N° {idSuplemento}</p>}
      <div className="col-md-12 p-2">
        <button
          className="btn btn-sm shadow mb-2 rounded" 
          data-bs-toggle="collapse"
          data-bs-target="#collapseSuplementos"
          aria-expanded="false"
          aria-controls="collapseSuplementos"
          style={{'backgroundColor':'#F3CDA9'}}
          onClick={()=>{ dispatch(suplementosSlice.actions.set_togleIconSupl(!togleIcon))}}
        > 
          {togleIcon ? <h4>-</h4> : <h4>+</h4>}
        </button>
        <div className="collapse" id="collapseSuplementos">
          <div className="card card-body">
            <div className="row p-1 mt-2">
              <div className="col-md-4">
                <div className="input-group input-group-sm">
                  <label className="input-group-text" htmlFor="inputGroupSelect01">Suplemento</label>
                  <select className="form-select" id="inputGroupSelect01" name="suplemento" value={suplemento} onChange={onInputChange}>
                    <option defaultValue="">Elegir...</option>
                    <option value="Hs Extras Normales">Hs Extras Normales</option>
                    <option value="Hs Extras al 50">Hs Extras al 50</option>
                    <option value="Hs Extras al 100">Hs Extras al 100</option>
                    <option value="Serv. Extraordinarios">Serv. Extraordinarios</option>
                    <option value="Dedicación Exclusiva">Dedicación Exclusiva</option>
                    <option value="Fallo de Caja">Fallo de Caja</option>
                    <option value="Tareas Riesgosas">Tareas Riesgosas</option>
                    <option value="Tareas de Inspección">Tareas de Inspección</option>
                    <option value="Tareas de Vigilancia">Tareas de Vigilancia</option>
                    <option value="Dedicación Funcional">Dedicación Funcional</option>
                    <option value="Adic. por Subrogancia">Adic. por Subrogancia</option>
                    <option value="Responsabilidad Jerarquica">Responsabilidad Jerarquica</option>
                    <option value="Tareas tuteladas y auxiliares">Tareas tuteladas y auxiliares</option>
                    <option value="Tareas Extraordinarias">Tareas Extraordinarias</option>
                    <option value="Reemplazo Transitorio">Reemplazo Transitorio</option>
                  </select>
                </div>
              </div>

              <div className="col-md-2">
                <div className="input-group input-group-sm">
                  <span className="input-group-text" id="inputGroup-sizing-sm">%</span>
                  <input 
                    type="text" 
                    name="porcentaje"
                    className="form-control" 
                    aria-label="Sizing example input" 
                    aria-describedby="inputGroup-sizing-sm"
                    value={porcentaje}
                    onChange={onInputChange}
                  />
                </div> 
              </div>

              <div className="col-md-3">
                <div className="input-group input-group-sm">
                  <span className="input-group-text" id="inputGroup-sizing-sm">Resolución Alta</span>
                  <input 
                    type="text" 
                    name="resAltaSupl"
                    className="form-control" 
                    aria-label="Sizing example input" 
                    aria-describedby="inputGroup-sizing-sm"
                    value={resAltaSupl}
                    onChange={onInputChange}
                  />
                </div> 
              </div>

              <div className="col-md-3">
                <div className="input-group input-group-sm">
                  <span className="input-group-text" id="inputGroup-sizing-sm">Fecha de Alta</span>
                  <input 
                    type="date" 
                    name="fechaAltaSupl"
                    className="form-control" 
                    aria-label="Sizing example input" 
                    aria-describedby="inputGroup-sizing-sm"
                    value={fechaAltaSupl}
                    onChange={onInputChange}
                  />
                </div> 
              </div>
            </div>

            <div className="row p-1">
              <div className="col-md-4">
                <div className="input-group input-group-sm">
                  <span className="input-group-text" id="inputGroup-sizing-sm">Fecha de Baja</span>
                  <input 
                    type="date" 
                    name="fechaBajaSupl"
                    className="form-control" 
                    aria-label="Sizing example input"  
                    aria-describedby="inputGroup-sizing-sm"
                    value={fechaBajaSupl}  
                    onChange={onInputChange}
                  />
                </div> 
              </div>

              <div className="col-md-4">
                <div className="input-group input-group-sm">
                  <span className="input-group-text" id="inputGroup-sizing-sm">Resolución Baja</span>
                  <input 
                    type="text" 
                    name="resBajaSupl"
                    className="form-control" 
                    aria-label="Sizing example input" 
                    aria-describedby="inputGroup-sizing-sm"
                    value={resBajaSupl}
                    onChange={onInputChange}
                  />
                </div> 
              </div>  

              <div className="col-md-4 p-1">
              {
                ((nivel===0)||(nivel===1))&&
                
                  editaSuplemento
                  ?
                    loading
                    ?
                      fLoading()
                    :
                      <div>
                        <button      
                          className="btn btn-sm shadow mb-2 rounded" 
                          onClick={()=>guardarEdicionSuplemento()}
                          style={{'backgroundColor':'#F3CDA9','marginRight':'2px'}}
                        >
                          Guardar Cambios
                        </button>

                        <button
                          onClick={()=>terminarEdicionSuplemento()}
                          className="btn btn-sm shadow mb-2 rounded" 
                          style={{'backgroundColor':'#F3CDA9'}}
                        >
                          Terminar Edición
                        </button>
                      </div>  
                  :
                    loading
                    ?
                      fLoading()
                    :
                      (nivel===0)&&
                      <>
                        <p style={{color:'red'}}>{msjSuplemento}</p>
                      
                        <button 
                          className="btn btn-sm shadow mb-3 rounded" 
                          style={{'backgroundColor':'#F3CDA9'}}
                          onClick={()=>submitFormSuplemento()}
                        >
                          Agregar Suplemento
                        </button>
                      </>

              }
              </div>
          
            </div>
          </div>
        </div>

        <div className="row p-1 border-bottom">
          <div className="col-md-12">
            <div className="p-1">Historial Suplementos:</div>
              <table className="table table-sm table-striped table-success table-bordered">
              <thead>
                <tr>
                  <th scope="col">id</th>
                  <th scope="col">Suplemento</th>
                  <th scope="col">Porcentaje</th>
                  <th scope="col">Res. Alta</th>
                  <th scope="col">Fecha Alta</th>
                  <th scope="col">Fecha Baja</th>
                  <th scope="col">Res. Baja</th>
                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              
              <tbody>
                {
                  suplementosList.map(suplemento => (
                  <tr key={suplemento.idSuplemento}>
                    <td>{suplemento.idSuplemento}</td>  
                    <td>{suplemento.suplemento}</td>
                    <td>{suplemento.porcentaje}</td>
                    <td>{suplemento.resolucionAlta}</td>
                    <td>{suplemento.fechaAlta}</td>
                    <td>{suplemento.fechaBaja}</td>
                    <td>{suplemento.resolucionBaja}</td>
                    <td>
                      <div className='row'>
                        <div className='col' style={{padding:'1px'}}>
                          <button 
                            type="button" 
                            className="btn btn-success btn-sm"
                            onClick={()=>selectSuplemento(suplemento,'ver')}
                          >
                            ver
                          </button>
                        </div>
                        {
                          ((nivel===0)||(nivel===1)) &&
                          
                            <>
                              <div className='col' style={{padding:'1px'}}>
                                <button 
                                  type="button" 
                                  className="btn btn-danger btn-sm"
                                  onClick={()=>selectSuplemento(suplemento,'editar')}
                                >
                                  editar
                                </button>
                              </div>

                              <div className='col' style={{padding:'1px'}}>
                                <button 
                                  type="button" 
                                  className="btn btn-danger btn-sm"
                                  onClick={()=>borrarSuplemento(suplemento)}
                                >
                                  borrar
                                </button>
                              </div>
                            </>
                        }
                      </div>
                    </td>
                  </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>      
      </div>           
    </>
  )
}
