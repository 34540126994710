import { useState } from 'react';

export const useForm = ( initialForm = {} ) => {

    const [formState, setFormState]            = useState(initialForm);
    const [togleIcon, setTogleIcon]            = useState(false);
    const [idSuplemento, setIdSuplemento]      = useState(0);  
    const [msjSuplemento,setMsjSuplemento]     = useState('');
    const [msjOkSuplemento,setMsjOkSuplemento] = useState('');
    const [loading,setLoading]                 = useState('');

    const onInputChange = ({ target }) => {
        const { name, value } = target;
        setFormState({
            ...formState,
            [ name ]: value
        });
    }

    const settogleIcon       = value => setTogleIcon(value);
    const setidSuplemento    = value => setIdSuplemento(value);
    const setmsjSuplemento   = value => setMsjSuplemento(value);
    const setmsjOkSuplemento = value => setMsjOkSuplemento(value);

    const setloading   = value => setLoading(value);
    const onResetForm  = () => setFormState( initialForm );

    return {
        ...formState,

        settogleIcon,
        togleIcon,

        setidSuplemento,
        idSuplemento,

        setmsjSuplemento,
        msjSuplemento,

        setmsjOkSuplemento,
        msjOkSuplemento,

        setloading,
        loading,

        setFormState,    

        onInputChange,
        onResetForm
    }
}