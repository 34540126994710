import React from 'react';
import { useSelector } from 'react-redux';
import Situacion       from './situacionAgente/Situacion';
import Suplementos     from './situacionAgente/Suplementos';
import Pases           from './situacionAgente/Pases';
import Conceptos from './situacionAgente/Conceptos';

export default function () {

  return (
    <>
      <ul className="nav nav-tabs mt-2" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button 
            className="nav-link active" 
            id="home-tab" 
            data-bs-toggle="tab" 
            data-bs-target="#situaciones" 
            type="button" 
            role="tab" 
            aria-controls="situaciones" 
            aria-selected="true"
          >
            Situacion del Agente
          </button>
        </li>

        <li className="nav-item" role="presentation">
          <button 
            className="nav-link" 
            id="profile-tab" 
            data-bs-toggle="tab" 
            data-bs-target="#suplementos" 
            type="button" 
            role="tab" 
            aria-controls="suplementos" 
            aria-selected="false"
          >
            Suplementos
          </button>
        </li>

        <li className="nav-item" role="presentation">
          <button 
            className="nav-link" 
            id="profile-tab" 
            data-bs-toggle="tab" 
            data-bs-target="#pases" 
            type="button" 
            role="tab" 
            aria-controls="pases" 
            aria-selected="false"
          >
            Pases
          </button>
        </li>     

         <li className="nav-item" role="presentation">
          <button 
            className="nav-link" 
            id="profile-tab" 
            data-bs-toggle="tab" 
            data-bs-target="#conceptos" 
            type="button" 
            role="tab" 
            aria-controls="conceptos" 
            aria-selected="false"
          >
            Conceptos
          </button>
        </li>     

      </ul>

      <div className="tab-content" id="myTabContent">
        
        <div className="tab-pane fade show active" id="situaciones" role="tabpanel" aria-labelledby="home-tab">
          <Situacion/>
        </div>

        <div className="tab-pane fade" id="suplementos" role="tabpanel" aria-labelledby="profile-tab">
          <Suplementos/>    
        </div>

        <div className="tab-pane fade" id="pases" role="tabpanel" aria-labelledby="profile-tab">
          <Pases/> 
        </div>

         <div className="tab-pane fade" id="conceptos" role="tabpanel" aria-labelledby="profile-tab">
          <Conceptos/> 
        </div>     

      </div>

    </>
  )
}
