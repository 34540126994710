import { useState } from "react";
import Cookies from 'universal-cookie';
import md5 from 'md5';
import { urlApi } from '../utils/api';
const axios = require('axios');
const cookies = new Cookies(); 

export default function () {

    const [idUsuario, setIdUsuario]             = useState(0);
    const [nombre, setNombre]                   = useState('');
    const [apellido, setApellido]               = useState('');
    const [user, setUser]                       = useState(''); 
    const [password, setPassword]               = useState('');
    const [passwordR, setPasswordR]             = useState('');
    const [permisoVer, setPermisoVer]           = useState(0);
    const [permisoEditar, setPermisoEditar]     = useState(0);
    const [permisoAltas, setPermisoAltas]       = useState(0);    
    const [dni, setDni]                         = useState(0);
    const [legajo, setLegajo]                   = useState(0);
    const [bloquearUser, setBloquearUser]       = useState(0);

    const [buscarUser, setBuscarUser]           = useState(false);  
    const [dniSearch, setDniSearch]             = useState(0); 
    const [verGuardarCambios,setGuardarCambios] = useState(false);
    const [checkCambioPass,setCheckCambioPass]  = useState(''); 

    const [msj, setMsj] = useState('');
    const [msjOk, setMsjOk] = useState('');
    const [msjUser, setMsjUser] = useState('');

    const [loading, setLoading] = useState(false);

    const handleInput = (e,setEstado)=>{
        setMsj('');
        setMsjUser('');    
        setMsjOk('');    
        setEstado(e.target.value)
    };

    const darDeAlta = (e)=>{

        if((nombre==='')||(apellido==='')||(dni===0)||(password==='')){
            setMsj("Hay datos del agente sin completar");
        }else if(password!==passwordR){
            setMsj("La contraseña debe ser igual en ambas entradas.");    
        }else{
           
            axios({
                method: 'post',
                url: urlApi+'/users',
                params:{dni:cookies.get('dataDni')},
                data: {
                    "idUsuario"      : 0,
                    "Nombre"         : nombre,
                    "Apellido"       : apellido,
                    "User"           : user,
                    "Password"       : md5(password),
                    "PermisoVer"     : parseInt(permisoVer),
                    "PermisoEditar"  : parseInt(permisoEditar),
                    "PermisoAltas"   : parseInt(permisoAltas),
                    "Dni"            : dni,
                    "Legajo"         : legajo,
                    "Bloqueado"      : bloquearUser
                }
            })
            .then(function () {
                setMsjOk('El usuario fue dado de alta correctamente')
            })
            .catch(function (error) {
                console.log('-->>',error);
            });
        }
    }

    const guardarCambios = (e)=>{
   
        let passwordTemp = '';

        if((nombre==='')||(apellido==='')||(dni===0)||(password==='')){
            setMsj("Hay datos del agente sin completar");
        }else{

            if(password!==checkCambioPass){
                passwordTemp = md5(password);
            }else{
                passwordTemp = password;
            }

            if(password!==passwordR){
                setMsj("La contraseña debe ser igual en ambas entradas.");    
            }else{        
                axios({
                    method: 'put',
                    url: urlApi+'/users/'+idUsuario,
                    params:{dni:cookies.get('dataDni')},
                    data: {
                        "Nombre"         : nombre,
                        "Apellido"       : apellido,
                        "User"           : user,
                        "Password"       : passwordTemp,
                        "PermisoVer"     : parseInt(permisoVer),
                        "PermisoEditar"  : parseInt(permisoEditar),
                        "PermisoAltas"   : parseInt(permisoAltas),
                        "Dni"            : dni,
                        "Legajo"         : legajo,
                        "Bloqueado"      : bloquearUser
                    }
                })
                .then(function () {
                    setMsjOk('Se actualizó correctamente.')
                })
                .catch(function (error) {
                    console.log('-->>',error);
                });
            }
        }
    }

    const getUser = ()=>{

         axios.get(urlApi+'/getuser',{params:{dni:cookies.get('dataDni'), dniAbuscar:dniSearch}})
        .then(res => {
            if(res.data.length>0){
                if(res.data[0].Dni.toString().includes('30596785')){
                    setMsj('El usuario no existe');
                }else{
                    setIdUsuario(res.data[0].idUser);
                    setNombre(res.data[0].Nombre);               
                    setApellido(res.data[0].Apellido);           
                    setUser(res.data[0].User);                   
                    setPassword(res.data[0].Password);           
                    setPasswordR(res.data[0].Password);         
                    setPermisoVer(res.data[0].PermisoVer);      
                    setPermisoEditar(res.data[0].PermisoEditar); 
                    setPermisoAltas(res.data[0].PermisoAltas);   
                    setDni(res.data[0].Dni);                    
                    setLegajo(res.data[0].Legajo);
                    setBloquearUser(res.data[0].Bloqueado);

                    setCheckCambioPass(res.data[0].Password);
                    setGuardarCambios(true);     
                }              
            }else{
                setMsjUser('El usuario no existe');    
            }
        })   
        .catch(function (error) {
          setMsj('ERROR: '+error)  
        }); 

    }


    const terminarEdicion = ()=>{
        setNombre('');               
        setApellido('');           
        setUser('');                   
        setPassword('');           
        setPasswordR('');         
        setPermisoVer(0);      
        setPermisoEditar(0); 
        setPermisoAltas(0);   
        setDni(0);                    
        setLegajo(0);   
        setGuardarCambios(false)
    }


    return (
        <>
          

                <div className="p-1">Alta y Edición de Usuario del Sistema</div>
               
                <div className="row p-2 border">
                    <div className="col-md-6">
                        <div className="input-group input-group-sm mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Nombre</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                aria-label="Sizing example input" 
                                aria-describedby="inputGroup-sizing-sm"
                                value={nombre}
                                onChange={(e)=>{handleInput(e,setNombre)}}
                                required
                            />          
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="input-group input-group-sm mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Apellido</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                aria-label="Sizing example input" 
                                aria-describedby="inputGroup-sizing-sm"
                                value={apellido}
                                onChange={(e)=>{handleInput(e,setApellido)}}
                                required
                            />
                        </div> 
                    </div>
                </div>
                

                <div className="row p-2 border">
                    <div className="col-md-6">
                        <div className="input-group input-group-sm mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Nombre Usuario</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                aria-label="Sizing example input" 
                                aria-describedby="inputGroup-sizing-sm"
                                value={user}
                                onChange={(e)=>{handleInput(e,setUser)}}
                                required
                            />
                        </div> 

                        <div className="input-group input-group-sm mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Contraseña</span>
                            <input 
                                type="password" 
                                className="form-control" 
                                aria-label="Sizing example input" 
                                aria-describedby="inputGroup-sizing-sm"
                                value={password}
                                onChange={(e)=>{handleInput(e,setPassword)}}
                                required
                            />
                        </div>

                        <div className="input-group input-group-sm mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Repetir contraseña</span>
                            <input 
                                type="password" 
                                className="form-control" 
                                aria-label="Sizing example input" 
                                aria-describedby="inputGroup-sizing-sm"
                                value={passwordR}
                                onChange={(e)=>{handleInput(e,setPasswordR)}}
                                required
                            />
                        </div>  
                    </div>

                    <div className="col-md-6">
                        <div className="input-group input-group-sm mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Puede Ver los Datos de Empleados?</span>
                            <select 
                                className="form-select" 
                                id="inputGroupSelect01" 
                                value={permisoVer.toString()} 
                                onChange={(e)=>{handleInput(e,setPermisoVer)}} 
                            >
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select>
                        </div> 
                
                        <div className="input-group input-group-sm mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Puede Editar los Datos de Empleados?</span>
                            <select 
                                className="form-select" 
                                id="inputGroupSelect01" 
                                value={permisoEditar.toString()} 
                                onChange={(e)=>{handleInput(e,setPermisoEditar)}} 
                            >
                                <option value="0">No</option>
                                <option value="1">Si</option>
                            </select>
                        </div> 
        
                        <div className="input-group input-group-sm mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Puede dar de Alta Empleados?</span>
                            <select 
                                className="form-select" 
                                id="inputGroupSelect01" 
                                value={permisoAltas.toString()} 
                                onChange={(e)=>{handleInput(e,setPermisoAltas)}} 
                            >
                                <option value="0">No</option>
                                <option value="1">Si</option>
                            </select>
                        </div>   


                    </div>   
                </div>

                <div className="row p-2 border">
                    <div className="col-md-4">
                        <div className="input-group input-group-sm mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Dni</span>
                            <input 
                                type="number" 
                                className="form-control" 
                                aria-label="Sizing example input" 
                                aria-describedby="inputGroup-sizing-sm"
                                value={dni}
                                onChange={(e)=>{handleInput(e,setDni)}}
                            />
                        </div> 
                    </div>

                    <div className="col-md-4">
                        <div className="input-group input-group-sm mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Legajo</span>
                            <input 
                                type="number" 
                                className="form-control" 
                                aria-label="Sizing example input" 
                                aria-describedby="inputGroup-sizing-sm"
                                value={legajo}
                                onChange={(e)=>{handleInput(e,setLegajo)}}
                            />
                        </div> 
                    </div>  

                    <div className="col-md-4">
                        <div className="input-group input-group-sm mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Bloquear acceso al usuario?</span>
                            <select 
                                className="form-select" 
                                id="inputGroupSelect01" 
                                value={bloquearUser.toString()} 
                                onChange={(e)=>{handleInput(e,setBloquearUser)}} 
                            >
                                <option value="0">No</option>
                                <option value="1">Si</option>
                            </select>
                        </div>   
                    </div>

                </div>

                <div className="row p-1 border-bottom">
                    {
                        !verGuardarCambios
                        ?    
                        <div className="col-md-12 p-2">
                            {
                                loading
                                ?
                                <div className="spinner-border text-info" role="status" style={{'marginTop':15}}>
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                :
                                <div>    
                                    <p style={{color:'red'}}>{msj}</p> 
                                    <p style={{color:'green'}}>{msjOk}</p>
                                </div>
                            }                    
                            <div>
                                <button 
                                    className="btn btn-sm shadow mb-1 rounded" 
                                    style={{'backgroundColor':'#F3CDA9'}}
                                    onClick={()=>darDeAlta()}
                                >
                                    Dar de Alta
                                </button>
                            </div>
                        </div>
                        :
                        <>
                            <div className="col"></div>
                            <div className="col-md-3 p-2">
                                <div>
                                    <button 
                                        className="btn btn-sm shadow mb-1 rounded" 
                                        style={{'backgroundColor':'#F3CDA9'}}
                                        onClick={()=>guardarCambios()}        
                                    >
                                        Guardar cambios del Usuario
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-2 p-2">
                                <div>
                                    <button 
                                        className="btn btn-sm shadow mb-1 rounded" 
                                        style={{'backgroundColor':'#F3CDA9'}}
                                        onClick={()=>terminarEdicion()}        
                                    >
                                        Terminar Edición
                                    </button>
                                </div>
                            </div>
                            <div className="col"></div>
                        </>
                    }
                </div>
               

         
            <div className="row p-1">
                <div className="col-md-2 mb-1">
                    <button 
                        className="btn btn-sm shadow mt-4 rounded" 
                        style={{'backgroundColor':'#F3CDA9'}}
                        onClick={()=>setBuscarUser(!buscarUser)}
                    >
                        Buscar Usuario para editar
                    </button>
                </div>   
                
            </div>
            
            <div className="row p-1">
                {
                    buscarUser 
                    ?
                        <>
                        <div className="col-md-6">
                            <div className="input-group input-group-sm mb-3">
                                <span className="input-group-text" id="inputGroup-sizing-sm">Ingresar dni a buscar:</span>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    aria-label="Sizing example input" 
                                    aria-describedby="inputGroup-sizing-sm"
                                    value={dniSearch}
                                    onChange={(e)=>{handleInput(e,setDniSearch)}}
                                />
                            </div> 
                            <p style={{color:'red'}}>{msjUser}</p>    
                        </div>

                        <div className="col-md-2">
                            <div>
                                <button 
                                    className="btn btn-sm shadow mb-5 rounded" 
                                    style={{'backgroundColor':'#F3CDA9'}}
                                    onClick={()=>{getUser()}}
                                >
                                    Ver
                                </button>
                            </div>
                        </div>
                        <div className="col"></div>
                        </>    
                    :
                        null    
                }   
            </div>    
        </>
    )
}
