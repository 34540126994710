import {useEffect} from 'react'
import { useForm } from '../hooks/useBody';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as dispsBody from '../store/slices/body';
import * as dispsSituacion from '../store/slices/situacion';
import { urlApi } from '../utils/api';
import VerEditarAgente from './VerEditarAgente';
import AltaAgente from './AltaAgente';
import AltaUsuario from './AltaUsuario';
import {SearchAgente} from './SearchAgente';
import Cookies from 'universal-cookie';
const axios = require('axios');
const cookies = new Cookies(); 

export default function Body(){

  const {
    result,
    verAltaAgente,
    verAltaUsuario,
    verEditVerAgente,
    verSearchAgente,
    volver,
    accion,  
    nivel
  } = useSelector((state) => state.body);

  const dispatch = useDispatch();
  const { 
    setloading,
    loading,
    onInputChange,
    onResetForm,
    legajo,
    apellido,
    secretaria,
    msj,
    setmsj
  } = useForm({
    legajo: '',
    apellido: '',
    secretaria: ''
  });

  useEffect(()=>{
    getPermisos();
    if(volver){//funcionalidad para el boton volver
      dispatch(dispsSituacion.set_togleIcon(false));
      dispatch(dispsSituacion.set_togleIconSupl(false));
      dispatch(dispsSituacion.set_togleIconPase(false));
      verSearchAgenteF(true); 
      dispatch(dispsBody.set_volver(false))
    }
  },[volver]);

  const navigate = useNavigate();


  const getPermisos = () => {
    let nivelResult = (cookies.get('dataPver'))+(cookies.get('dataPeditar'))+(cookies.get('dataPaltas'));  
  
    if(nivelResult==='111'){
      dispatch(dispsBody.set_nivel(0));
    }else if(nivelResult==='110'){
      dispatch(dispsBody.set_nivel(1));
    }else if(nivelResult==='100'){
      dispatch(dispsBody.set_nivel(2));
    }
  }

  var verAltaAgenteF = (value) => {
    dispatch(dispsBody.set_verSearchAgente(false));
    dispatch(dispsBody.set_verEditVerAgente(false));
    dispatch(dispsBody.set_verAltaUsuario(false));
    dispatch(dispsBody.set_verAltaAgente(value));
  }

  var verAltaUsuarioF = (value) => {
    dispatch(dispsBody.set_verSearchAgente(false));
    dispatch(dispsBody.set_verEditVerAgente(false));
    dispatch(dispsBody.set_verAltaAgente(false));
    dispatch(dispsBody.set_verAltaUsuario(value));
  }

  var verSearchAgenteF = (value) => {
    dispatch(dispsBody.set_verAltaAgente(false));
    dispatch(dispsBody.set_verAltaUsuario(false));
    dispatch(dispsBody.set_verEditVerAgente(false));
    dispatch(dispsSituacion.set_conceptoList([]));
    setloading(true);

    if(legajo.length>0){

      axios.get(`${urlApi}/legajo/${legajo}`,{params:{dni:cookies.get('dataDni')}})
        .then(res => {

          const agente = res.data;
          const agenteArray = [];
          agenteArray.push(agente);
          dispatch(dispsBody.set_result(agenteArray));
          dispatch(dispsBody.set_verSearchAgente(value));
          setloading(false);

        })   
        .catch(function (error) {
          setmsj('Error: '+error);  
        });  

    }else if(apellido.length>0){
      axios.get(`${urlApi}/apellido/${apellido}`,{params:{dni:cookies.get('dataDni')}})
        .then(res => {

          let agentes = res.data;
          if(agentes.length===0){agentes=['']}
          dispatch(dispsBody.set_result(agentes));
          dispatch(dispsBody.set_verSearchAgente(value));
          setloading(false);

        })   
        .catch(function (error) {
          setmsj('Error: '+error);  
        });

    }else if(secretaria.length>0){
      axios.get(`${urlApi}/secretaria/${secretaria}`,{params:{dni:cookies.get('dataDni')}})
        .then(res => {

          let agentes = res.data;
          if(agentes.length===0){agentes=['']}
          dispatch(dispsBody.set_result(agentes));
          dispatch(dispsBody.set_verSearchAgente(value));
          setloading(false);

        })   
        .catch(function (error) {
          setmsj('Error: '+error);      
        });  

    }else{
      setloading(false);
      setmsj('Debe Ingresar un dato a buscar'); 
    }
  }

  var editVerAgente = (legajo,accion) => {
    dispatch(dispsBody.set_accion(accion));

    axios.get(`${urlApi}/legajo/${legajo}`,{params:{dni:cookies.get('dataDni')}})
      .then(res => {
        const agente = res.data;
        const agenteArray = [];
        agenteArray.push(agente);
        dispatch(dispsBody.set_result(agenteArray));
        dispatch(dispsBody.set_verSearchAgente(false));
        dispatch(dispsBody.set_verEditVerAgente(true));
        setloading(false);
      })   
      .catch(function (error) {
        setmsj('Error: '+error); 
      });
  }

  return (

    <div className="container-fluid">
      <div className="row">
        <div className='col text-center' style={{color:'red'}}>
          <p>{msj}</p>
        </div>
      </div>
      <div className="row">

          <div className="col-md-2 text-center border-top border-end">
            <div className="mt-2"><p style={{'color':'blue'}}>Usuario: {cookies.get('dataNombre')} {cookies.get('dataApellido')}</p></div>
            <div className="accordion mt-1" id="accordionExample">

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Buscar Empleado
                  </button>
                </h2>

                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                  <div className="accordion-body">
                      <div className="form-group p-1">
                        <label htmlFor="inputLegajo" style={{'fontSize':13}}>Buscar por Legajo</label>
                        <input 
                          type="number"
                          name="legajo"
                          autoFocus
                          className="form-control form-control-sm" 
                          id="inputLegajoId" 
                          placeholder="Numero de legajo"
                          value    = {legajo}
                          onChange = {onInputChange}
                          onClick  = {onResetForm}
                        />
                      </div>

                      <div className="form-group p-1">
                        <label htmlFor="inputApellido" style={{'fontSize':13}}>Buscar por Apellido</label>
                        <input 
                          type="text"
                          name="apellido"
                          className="form-control form-control-sm" 
                          id="inputApellidoId" 
                          placeholder="Apellido"
                          value    = {apellido}
                          onChange = {onInputChange}  
                          onClick  = {onResetForm}
                        />
                      </div>

                      <div className="form-group p-1">
                        <label htmlFor="inputApellido" style={{'fontSize':13}}>Buscar por Secretaria</label>
                        <input 
                          type="text"
                          name="secretaria"
                          className="form-control form-control-sm" 
                          id="inputSecretariaId" 
                          placeholder="Secretaria"
                          value    = {secretaria}
                          onChange = {onInputChange}  
                          onClick  = {onResetForm}
                        />
                      </div>
                      
                      <div className="p-2">
                        <button 
                          className="btn btn-sm shadow mb-1 rounded" 
                          style={{'backgroundColor':'#F3CDA9'}}
                          onClick={()=>{verSearchAgenteF(true)}}    
                        >
                          Ver
                        </button>
                      </div>

                      <div className="p-2">
                        <button 
                          className="btn btn-sm shadow mb-5 rounded" 
                          style={{'backgroundColor':'#F3CDA9'}}
                          onClick={()=>{navigate("/consultas")}}    
                        >
                          Consultas
                        </button>
                      </div>

                  </div>
                </div>
              </div>
        
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Altas
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample2">
                  <div className="accordion-body">
                      {
                        (nivel===0)&&
                        <div className="p-2">
                          <button 
                            className="btn btn-sm shadow mb-2 rounded" 
                            style={{'backgroundColor':'#F3CDA9'}}
                            onClick={()=>verAltaAgenteF(true)}
                          >
                            Alta de Empleado
                          </button>
                        </div>
                      }
                
                      {
                        (nivel===0)&&
                        <div>
                          <button 
                            className="btn btn-sm shadow mb-5 rounded" 
                            style={{'backgroundColor':'#F3CDA9'}}
                            onClick={()=>verAltaUsuarioF(true)}
                          >
                            Admin Usuarios Sistema
                          </button>
                        </div>
                      }
                  </div>
                </div>
              </div> 
            </div>     
          </div>

        <div className="col-md-10 text-center border-top">     
          { 
            loading &&
            <div className="spinner-border text-info" role="status" style={{'marginTop':15}}>
                <span className="visually-hidden">Loading...</span>
            </div>
          }

          {
            !loading && ((verSearchAgente && (result.length>0)) && 
              <SearchAgente editVerAgente={editVerAgente}/>
            )
          }   

          {
            !loading && (verEditVerAgente && <VerEditarAgente accion={accion}/>)
          }     

          {!loading && (verAltaAgente  && <AltaAgente />)}

          {!loading && (verAltaUsuario && <AltaUsuario />)}

        </div>
      </div>
    </div>
  )

}