import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as dispsPases from '../../store/slices/situacion';
import Cookies from 'universal-cookie';
import {useForm} from '../../hooks/usePases'
import {convertFecha} from '../../utils/convertFechas';
import { urlApi } from '../../utils/api';

const cookies = new Cookies(); 
const axios = require('axios');

export default function () {

  useEffect(() => {  
    getPases();
  },[]);

  const { result,nivel } = useSelector((state) => state.body);
  const { editaPase,pasesList,togleIcon } = useSelector((state) => state.pases);
  const dispatch = useDispatch();
  
  //hooks locales
  const { 
      setidPase,
      idPase,
      setmsjPase,
      msjPase,
      setmsjOkPase,
      setloading,
      loading,
      setFormState,    
      onInputChange,
      origen,
      destino,
      fecha,
      resNotaProv,
      observaciones

  } = useForm({
      origen        : '',
      destino       : '',  
      fecha         : '',
      resNotaProv   : 0,
      observaciones : ''
  });  


  const getPases = () => {
    setloading(true);
    axios.get(`${urlApi}/paseList/${result[0].idAgente}`,{params:{dni:cookies.get('dataDni')}})

      .then(resPasesList=> {

        let fechaPaseStr='';
        let listPasesTemp=[];

        resPasesList.data.map(pase => {

          (pase.fecha.includes('1969')||pase.fecha.includes('1970')) ? fechaPaseStr='' : fechaPaseStr=pase.fecha;
         
          var options = { year:'numeric', month:'long', day:'numeric', timeZone:'America/Argentina/Buenos_Aires'};
         
          if(fechaPaseStr!==''){
            let fechaPaseFormat = new Date(fechaPaseStr);
            fechaPaseFormat.setMinutes(fechaPaseFormat.getMinutes() + fechaPaseFormat.getTimezoneOffset())//compensacion diferencia de dia
            fechaPaseStr=fechaPaseFormat.toLocaleDateString('es-AR',options);  
          }

          pase.fecha=fechaPaseStr;
          listPasesTemp.push(pase);

        })

        dispatch(dispsPases.set_pasesList( listPasesTemp.reverse() ));
        setloading(false);

        setFormState({
          origen:'',
          destino:'',
          fecha:'',
          resNotaProv:0,
          observaciones:''
        })

      })   
      .catch(function (error) {
        setmsjPase('ERROR!: '+error);  
      });
  }

  const submitFormPase = ()=>{

    setmsjPase('');

    let fechaFormat = new Date(fecha+'T00:00:00');
    let agregar = true;

    if(resNotaProv===0){
      agregar = false;
      setmsjPase('Debe ingresar un número de resolución, nota o providencia')
    }

    if(origen===''){
      agregar = false;
      setmsjPase('Debe ingresar el lugar de Origen')
    }

    if(destino===''){
      agregar = false;
      setmsjPase('Debe ingresar el lugar de Destino')
    }
   
    if(agregar){
      setloading(true);
      axios({
        method: 'post',
        url: urlApi+'/pase',
        params:{dni:cookies.get('dataDni')},
        data: {
          "idPase"        : 0,
          "origen"        : origen,
          "destino"       : destino, 
          "fecha"         : fechaFormat, 
          "ResNotaProv"   : resNotaProv, 
          "Observaciones" : observaciones, 
          "idAgente"      : result[0].idAgente 
        }
      })
      .then(function () {
        getPases();
      })
      .catch(function (error) {
        console.log('-->>',error);
      });
    }

  }
 
  const selectPase = (pase,accion)=>{

    setmsjPase('');

    if(accion==='editar'){
      dispatch(dispsPases.set_editaPase(true));
      setidPase(parseInt(pase.idPase));
    }else if(accion==='ver'){dispatch(
      dispsPases.set_editaPase(false));
    }
 
    setFormState({
      origen        : pase.origen,
      destino       : pase.destino,
      fecha         : convertFecha(pase.fecha),
      resNotaProv   : parseInt(pase.ResNotaProv),
      observaciones : pase.Observaciones
    })
  }

  const borrarPase = (pase) =>{

    setmsjPase('');    

    if (window.confirm('Confirma borrar el pase N° '+pase.idPase+'?')) {
      setloading(true);
      axios({
        method: 'delete',
        url: urlApi+'/pase/'+pase.idPase,
        params:{dni:cookies.get('dataDni')},
      })
      .then(function () {
        getPases();
        setmsjOkPase("El Pase N° "+pase.idPase+" se eliminó correctamente");
      })
      .catch(function (error) {
        console.log('-->>',error);
      });
    }
  }

  const terminarEdicionPase = () =>{
    setFormState({
      origen:'',
      destino:'',
      fecha:'',
      resNotaProv:0,
      observaciones:''
    })
  }

  const guardarEdicionPase = ()=>{

    setloading(true);

    let fechaFormat = new Date(fecha+'T00:00:00');

    if( ((origen==='')||(destino===''))||(resNotaProv===0)||(resNotaProv==='0')||(resNotaProv==='') ){
        setmsjPase('Se debe ingresar un Origen, Destino y un N° de Resolución, Nota o Providencia')
    }else{
      axios({
        method: 'put',
        url: urlApi+'/pase/'+idPase,
        params:{dni:cookies.get('dataDni')},
        data: {
          "origen"        : origen,
          "destino"       : destino, 
          "fecha"         : fechaFormat, 
          "ResNotaProv"   : resNotaProv, 
          "Observaciones" : observaciones, 
          "idAgente"      : result[0].idAgente 
        }
      })
      .then(function () {
        getPases();
        setmsjOkPase("El pase N° "+idPase+" se editó correctamente");
      })
      .catch(function (error) {
        console.log('-->>',error);
      });
    }  
  }

  const fLoading = ()=>{
    return( 
      <div className="spinner-border text-info" role="status" style={{'marginTop':15}}>
        <span className="visually-hidden">Loading...</span>
      </div>
    )
  }

return (
  <>
    {editaPase && <p style={{'color':'red'}}>Vas a editar el Pase N° {idPase}</p>}
    <div className="col-md-12 p-2">

      <button
          className="btn btn-sm shadow mb-2 rounded" 
          data-bs-toggle="collapse"
          data-bs-target="#collapsePases"
          aria-expanded="false"
          aria-controls="collapsePases"
          style={{'backgroundColor':'#F3CDA9'}}
          onClick={()=>{dispatch( dispsPases.set_togleIconPase(!togleIcon) )}}//settogleIcon(!togleIcon)}}
        
      > 
          {togleIcon ? <h4>-</h4> : <h4>+</h4>}
      </button>
      
      <div className="collapse" id="collapsePases">
        <div className="row p-1 mt-2">

          <div className="col-md-4">
              <div className="input-group input-group-sm">
              <span className="input-group-text" id="inputGroup-sizing-sm">Origen</span>
              <input 
                  type="text" 
                  name="origen"
                  className="form-control" 
                  aria-label="Sizing example input" 
                  aria-describedby="inputGroup-sizing-sm"
                  value={origen}
                  onChange={onInputChange}
              />
              </div> 
          </div>

          <div className="col-md-4">
              <div className="input-group input-group-sm">
              <span className="input-group-text" id="inputGroup-sizing-sm">Destino</span>
              <input 
                  type="text" 
                  name="destino"
                  className="form-control" 
                  aria-label="Sizing example input" 
                  aria-describedby="inputGroup-sizing-sm"
                  value={destino}
                  onChange={onInputChange}
              />
              </div> 
          </div>

          <div className="col-md-4">
              <div className="input-group input-group-sm">
              <span className="input-group-text" id="inputGroup-sizing-sm">Fecha</span>
              <input 
                  type="date" 
                  name="fecha"
                  className="form-control" 
                  aria-label="Sizing example input" 
                  aria-describedby="inputGroup-sizing-sm"
                  value={fecha}
                  onChange={onInputChange}
              />
              </div> 
          </div>

        </div>

        <div className="row p-1 mt-2">

          <div className="col-md-4">
              <div className="input-group input-group-sm">
              <span className="input-group-text" id="inputGroup-sizing-sm">Resolución/Nota o Providencia</span>
              <input 
                  type="number" 
                  name="resNotaProv"
                  className="form-control" 
                  aria-label="Sizing example input" 
                  aria-describedby="inputGroup-sizing-sm"
                  value={resNotaProv}
                  onChange={onInputChange}
              />
              </div> 
          </div>

          <div className="col-md-5">
              <div className="input-group input-group-sm">
              <span className="input-group-text" id="inputGroup-sizing-sm">Observaciones</span>
              <textarea 
                  type="textarea" 
                  name="observaciones"
                  className="form-control" 
                  aria-label="Sizing example input" 
                  aria-describedby="inputGroup-sizing-sm"
                  value={observaciones}
                  onChange={onInputChange}
              />
              </div> 
          </div>

          <div className="col-md-3">
              {
              ((nivel===0)||(nivel===1))&&
                
                  editaPase
                  ?
                    loading
                    ?
                        fLoading()
                    :
                        <div>
                        <button      
                            className="btn btn-sm shadow mb-2 rounded" 
                            onClick={()=>guardarEdicionPase()}
                            style={{'backgroundColor':'#F3CDA9','marginRight':'2px'}}
                        >
                            Guardar Cambios
                        </button>

                        <button
                            onClick={()=>terminarEdicionPase()}
                            className="btn btn-sm shadow mb-2 rounded" 
                            style={{'backgroundColor':'#F3CDA9'}}
                        >
                            Terminar Edición
                        </button>
                        </div>  
                  :
                    loading
                    ?
                        fLoading()
                    :
                        (nivel===0) &&
                        <>
                          <p style={{color:'red'}}>{msjPase}</p>
                          <button 
                              className="btn btn-sm shadow mb-3 rounded" 
                              style={{'backgroundColor':'#F3CDA9'}}
                              onClick={(e)=>{submitFormPase()}}  
                          >
                              Agregar Pase
                          </button>
                        </>               
              }
          </div>

        </div>
      </div>

      <div className="row p-1 border-bottom">
          <div className="col-md-12">
          <div className="p-1">Historial Pases:</div>
            <table className="table table-sm table-striped table-success table-bordered">
              <thead>
                <tr> 
                  <th scope="col">idPase</th>
                  <th scope="col">Origen</th>
                  <th scope="col">Destino</th>
                  <th scope="col">Fecha</th>
                  <th scope="col">Res./Nota. o Prov </th>
                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              
              <tbody>
                {
                  pasesList.map(pase => (
                    <tr key={pase.idPase}>
                        <td>{pase.idPase}</td>
                        <td>{pase.origen}</td>
                        <td>{pase.destino}</td>
                        <td>{pase.fecha}</td>
                        <td>{pase.ResNotaProv}</td>
                        <td>
                          <div className='row'>
                              <div className='col' style={{padding:'1px'}}>
                              <button 
                                  type="button" 
                                  className="btn btn-success btn-sm"
                                  onClick={()=> selectPase(pase,'ver')}    
                              >
                                  ver
                              </button>
                              </div>
                              {
                              ((nivel===0)||(nivel===1)) &&
                                <>
                                  <div className='col' style={{padding:'1px'}}>
                                      <button 
                                      type="button" 
                                      className="btn btn-danger btn-sm"
                                      onClick={()=> selectPase(pase,'editar')}
                                      >
                                      Editar
                                      </button>
                                  </div>

                                  <div className='col' style={{padding:'1px'}}>
                                      <button 
                                      type="button" 
                                      className="btn btn-danger btn-sm"
                                      onClick={()=> borrarPase(pase)}
                                      >
                                      Borrar
                                      </button>
                                      
                                  </div>
                                </>
                              }
                          </div>
                        </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
      </div>

    </div>  
  </>
)}
