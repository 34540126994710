import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {situacionSlice} from '../../store/slices/situacion';
import {convertFecha} from '../../utils/convertFechas';
import {useForm} from '../../hooks/useSituacion';
import Cookies from 'universal-cookie';
import { urlApi } from '../../utils/api';
const cookies = new Cookies(); 
const axios = require('axios');


export default function () {
 
  useEffect(() => {  getSituaciones(); },[]);
  const {result} = useSelector((state) => state.body);

  const {editaSituacion,situacionList,togleIcon}  = useSelector((state) => state.situacion);
  const {nivel}  = useSelector((state) => state.body);
  const dispatch = useDispatch();
  
  //hooks locales
  const { 
      onInputChange,
      setloading,
      loading,    

      setidSituacion,
      idSituacion,

      setmsjSituacion,
      msjSituacion,

      setmsjOkSituacion,
      msjOkSituacion,

      setFormState,

      onResetForm,

      situacion,
      fechaAlta,
      resolucionAlta,
      fechaBaja,
      resolucionBaja,
      movimientos

  } = useForm({
      situacion      : '',
      fechaAlta      : '',  
      resolucionAlta : 0,
      fechaBaja      : '',
      resolucionBaja : 0,
      movimientos    : ''
  });

  const getSituaciones = () => {

    setloading(true);
   
    axios.get(`${urlApi}/situacionList/${result[0].idAgente}`,{params:{dni:cookies.get('dataDni')}})

      .then(resSituacionList => {
        let fechaAltaStr='';
        let fechaBajaStr='';
        let listSituacionTemp=[];

        resSituacionList.data.map(situacion => {
          (situacion.fechaAlta.includes('1969')||situacion.fechaAlta.includes('1970'))?fechaAltaStr='':fechaAltaStr=situacion.fechaAlta;
          (situacion.fechaBaja.includes('1969')||situacion.fechaBaja.includes('1970'))?fechaBajaStr='':fechaBajaStr=situacion.fechaBaja;

          var options = { year:'numeric', month:'long', day:'numeric', timeZone:'America/Argentina/Buenos_Aires'};
        
          if(fechaAltaStr!==''){
            let fechaAltaFormat = new Date(fechaAltaStr);
            fechaAltaFormat.setMinutes(fechaAltaFormat.getMinutes() + fechaAltaFormat.getTimezoneOffset())//compensacion diferencia de dia
            fechaAltaStr=fechaAltaFormat.toLocaleDateString('es-AR',options);  
          }
          if(fechaBajaStr!==''){
            let fechaBajaFormat = new Date(fechaBajaStr);
            fechaBajaFormat.setMinutes(fechaBajaFormat.getMinutes() + fechaBajaFormat.getTimezoneOffset())//compensacion diferencia de dia
            fechaBajaStr=fechaBajaFormat.toLocaleDateString('es-AR',options);
          }
          situacion.fechaAlta=fechaAltaStr;
          situacion.fechaBaja=fechaBajaStr;
          listSituacionTemp.push(situacion);
        })

        dispatch(situacionSlice.actions.set_situacion(listSituacionTemp.reverse()));       
        setloading(false);
        dispatch(situacionSlice.actions.set_editaSituacion(false));

      })   
      .catch(function (error) {
        setmsjSituacion('ERROR!: '+error)  
      });
  }

  const submitFormSituacion = ()=>{

    if( (situacion==='') && ((resolucionBaja!==0)&&(fechaBaja!=='')) ){
      setmsjSituacion('Para registrar una baja debe seleccionar en que situacion esta el empleado.');
      setloading(false);
    }else if( ((situacion==='')||(fechaAlta==='')||(resolucionAlta===0)) && ((resolucionBaja===0)&&(fechaBaja==='')) ){
      setmsjSituacion('Se debe completar la Situación, fecha de alta y N° de resolución de alta.');
      setloading(false);
    }else{
      setloading(true);

      let fechaAltaFormat = new Date(fechaAlta+'T00:00:00');
      let fechaBajaFormat = new Date(fechaBaja+'T00:00:00');
    
      axios({
        method: 'post',
        url: urlApi+'/situacion',
        params:{dni:cookies.get('dataDni')},
        data: {
          "idSituacion"    : 0,
          "situacion"      : situacion,
          "fechaAlta"      : fechaAltaFormat, 
          "resolucionAlta" : resolucionAlta, 
          "fechaBaja"      : fechaBajaFormat, 
          "resolucionBaja" : resolucionBaja, 
          "movimientos"    : movimientos, 
          "idAgente"       : result[0].idAgente 
        }
      })
      .then(function () {
        getSituaciones();
      })
      .catch(function (error) {
        console.log('-->>',error);
      });
    }
  }

  const selectSituacion = (situacion,accion)=>{

      setmsjSituacion('');

      if(accion==='editar'){
        dispatch(situacionSlice.actions.set_editaSituacion(true));
        setidSituacion(parseInt(situacion.idSituacion));
      }else if(accion==='ver'){
        dispatch(situacionSlice.actions.set_editaSituacion(false));
      }

      setFormState({
        situacion      : situacion.situacion,
        fechaAlta      : convertFecha(situacion.fechaAlta),
        resolucionAlta : situacion.resolucionAlta,
        fechaBaja      : convertFecha(situacion.fechaBaja),
        resolucionBaja : situacion.resolucionBaja,
        movimientos    : situacion.movimientos
      })

  }

  const borrarSituacion = (situacion) =>{

      setmsjSituacion('');

      if (window.confirm('Confirma borrar la situacion N° '+situacion.idSituacion+' ?')) {
        setloading(true);
        axios({
            method: 'delete',
            url: urlApi+'/situacion/'+situacion.idSituacion,
            params:{dni:cookies.get('dataDni')},
        })
        .then(function () {
            getSituaciones();
            setmsjOkSituacion("La situacion N° "+situacion.idSituacion+" se eliminó correctamente");
        })
        .catch(function (error) {
            console.log('-->>',error);
        });
      
      }
  }

  const terminarEdicionSituacion = () =>{
      setFormState({
        situacion      : '',
        fechaAlta      : '',  
        resolucionAlta : 0,
        fechaBaja      : '',
        resolucionBaja : 0,
        movimientos    : ''
      });
      dispatch(situacionSlice.actions.set_editaSituacion(false));
  }

  const guardarEdicionSituacion = ()=>{

      setmsjSituacion('');

      if( (situacion==='') && ((resolucionBaja!==0)&&(fechaBaja!=='')) ){
          setmsjSituacion('Para registrar una baja debe seleccionar en que situacion esta el empleado.');
      }else if( ((situacion==='')||(fechaAlta==='')||(resolucionAlta===0)) && ((resolucionBaja===0)&&(fechaBaja==='')) ){
          setmsjSituacion('Se debe completar la Situación, fecha de alta y N° de resolución de alta.');
      }else{
          let fechaAltaFormat = new Date(fechaAlta+'T00:00:00');
          let fechaBajaFormat = new Date(fechaBaja+'T00:00:00');
          setloading(true);
          axios({
              method: 'put',
              url: urlApi+'/situacion/'+idSituacion,
              params:{dni:cookies.get('dataDni')},
              data: {
              "situacion"      : situacion,
              "fechaAlta"      : fechaAltaFormat, 
              "resolucionAlta" : resolucionAlta, 
              "fechaBaja"      : fechaBajaFormat, 
              "resolucionBaja" : resolucionBaja, 
              "movimientos"    : movimientos, 
              "idAgente"       : result[0].idAgente 
              }
          })
          .then(function () {
            getSituaciones();
            setmsjSituacion("La situacion N° "+idSituacion+" se edito correctamente");
          })
          .catch(function (error) {
              console.log('-->>',error);
          });
      } 
  }

  const fLoading = ()=>{
    return( 
      <div className="spinner-border text-info" role="status" style={{'marginTop':15}}>
        <span className="visually-hidden">Loading...</span>
      </div>
    )
  }

  return (
    <>
      <div className="row p-1 border-bottom">
        {editaSituacion && <p style={{'color':'red'}}>Vas a editar la situación N° {idSituacion}</p>}
        <div className="col-md-12 p-2">
          <button
            className="btn btn-sm shadow mb-2 rounded" 
            data-bs-toggle="collapse"
            data-bs-target="#collapseSituacion"
            aria-expanded="false"
            aria-controls="collapseSituacion"
            style={{'backgroundColor':'#F3CDA9'}}
            onClick={()=>{ dispatch(situacionSlice.actions.set_togleIcon(!togleIcon)) }}
          > 
            {togleIcon ? <h4>-</h4> : <h4>+</h4>}
          </button>
          <div className="collapse" id="collapseSituacion">
            <div className="card card-body">
                <div className="row mt-2">

                  <div className="col-md-4">
                      <div 
                        className="input-group input-group-sm mb-3"
                      >
                        <label className="input-group-text" htmlFor="inputGroupSelect01">Situación</label>
                        <select 
                          className="form-select"
                          name="situacion"
                          id="inputGroupSelect01" 
                          value={situacion} 
                          onChange={onInputChange} 
                        >
                          <option defaultValue="">Elegir...</option>
                          <option value="Permanente">Permanente</option>
                          <option value="Subrogancia/reemplazo">Subrogancia/reemplazo</option>
                          <option value="Transitoria">Transitoria</option>
                          <option value="Contratado">Contratado</option>
                          <option value="Horas Catedras">Horas Cátedras</option>
                        </select>
                      </div>
                  </div>

                  <div className="col-md-4">
                    <div className="input-group input-group-sm">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Fecha de Alta</span>
                      <input 
                        type="date" 
                        name="fechaAlta"
                        className="form-control" 
                        aria-label="Sizing example input" 
                        aria-describedby="inputGroup-sizing-sm"
                        value={fechaAlta}
                        onChange={onInputChange}
                        required
                      />
                    </div> 
                  </div>

                  <div className="col-md-4">
                    <div className="input-group input-group-sm">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Resolución Alta</span>
                      <input 
                        type="number" 
                        name="resolucionAlta"
                        className="form-control" 
                        aria-label="Sizing example input" 
                        aria-describedby="inputGroup-sizing-sm"
                        value={resolucionAlta}
                        onChange={onInputChange}
                      />
                    </div> 
                  </div>

                </div>  

                <div className="row mt-2">
                  <div className="col-md-6">
                    <div className="input-group input-group-sm mb-3">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Fecha de baja/renuncia/jubilación</span>
                      <input 
                        type="date" 
                        name="fechaBaja"
                        className="form-control" 
                        aria-label="Sizing example input" 
                        aria-describedby="inputGroup-sizing-sm"
                        value={fechaBaja}
                        onChange={onInputChange}
                      />
                    </div> 
                  </div>

                  <div className="col-md-6">
                    <div className="input-group input-group-sm mb-3">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Resolución Baja</span>
                      <input 
                        type="number"
                        name="resolucionBaja"
                        className="form-control" 
                        aria-label="Sizing example input" 
                        aria-describedby="inputGroup-sizing-sm"
                        value={resolucionBaja}
                        onChange={onInputChange}
                      />
                    </div> 
                  </div>
                </div>      

                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="input-group input-group-sm mb-3">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Movimientos/Otros</span>
                      <textarea 
                        type="textarea" 
                        name="movimientos"
                        className="form-control" 
                        aria-label="Sizing example input" 
                        aria-describedby="inputGroup-sizing-sm"
                        value={movimientos}
                        onChange={onInputChange}
                      />
                    </div> 
                  </div>
                </div>

                <div className="row p-1">
                  <div className="col-md-12 p-2">
                    {
                      ((nivel===0)||(nivel===1))&&
                      
                        editaSituacion
                        ?
                          loading
                          ?
                           fLoading()
                          :
                            <div>
                              <button      
                                className="btn btn-sm shadow mb-2 rounded" 
                                onClick={()=>guardarEdicionSituacion()}
                                style={{'backgroundColor':'#F3CDA9','marginRight':'2px'}}
                              >
                                Guardar Cambios
                              </button>

                              <button
                                onClick={()=>terminarEdicionSituacion()}
                                className="btn btn-sm shadow mb-2 rounded" 
                                style={{'backgroundColor':'#F3CDA9'}}
                              >
                                Terminar Edición
                              </button>
                            </div>
                          
                        : (nivel===0)&&

                          loading
                          ?
                           fLoading()
                          :  
                            <>
                            <p style={{color:'red'}}>{msjSituacion}</p>
                            <button
                              onClick={()=>submitFormSituacion()}
                              className="btn btn-sm shadow mb-2 rounded" 
                              style={{'backgroundColor':'#F3CDA9'}}
                            >
                              Agregar Situación
                            </button>
                            </>
              
                    }
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row p-1 border-bottom">
        <div className="col-md-12">
          <div className="p-1">Historial Situación:</div>
          <table className="table table-sm table-striped table-success table-bordered">
            <thead>
              <tr>
                <th scope="col">id</th>
                <th scope="col">Situacion</th>
                <th scope="col">Fecha Alta</th>
                <th scope="col">Res. Alta</th>
                <th scope="col">Fecha Baja</th>
                <th scope="col">Res. Baja</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
    
            <tbody>
              {
                situacionList.map(situacion => (
                  <tr key={situacion.idSituacion}>
                    <td>{situacion.idSituacion}</td>
                    <td>{situacion.situacion}</td>
                    <td>{situacion.fechaAlta}</td>
                    <td>{situacion.resolucionAlta}</td>
                    <td>{situacion.fechaBaja}</td>
                    <td>{situacion.resolucionBaja}</td>
                    <td>
                      <div className='row'>
                        <div className='col' style={{padding:'1px'}}>
                          <button 
                            type="button" 
                            className="btn btn-success btn-sm"
                        
                            onClick={()=>selectSituacion(situacion,'ver')}
                          >
                            ver
                          </button>
                        </div>
                        {
                        ((nivel===0)||(nivel===1))
                        ? <> 
                            <div className='col' style={{padding:'1px'}}>
                              <button 
                                type="button" 
                                className="btn btn-danger btn-sm"
                                onClick={()=>selectSituacion(situacion,'editar')}
                              >
                                editar
                              </button>
                            </div>
                            <div className='col' style={{padding:'1px'}}>
                              <button 
                                type="button" 
                                className="btn btn-danger btn-sm"
                                onClick={()=>borrarSituacion(situacion)}
                              >
                                borrar
                              </button>
                            </div>    
                          </>
                        :
                          null
                        }
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}