import { useState } from 'react';

export const useForm = ( initialForm = {} ) => {
  
    const [ formState, setFormState ] = useState( initialForm );
    const [ togleIcon, setTogleIcon ] = useState(false);
    const [ idPase, setIdPase ]       = useState(0);  

    const [ msjPase,setMsjPase ]      = useState('');
    const [ msjOkPase,setMsjOkPase ]  = useState('');

    const [ loading,setLoading ]      = useState('');

    const onInputChange = ({ target }) => {
        const { name, value } = target;
        setFormState({
            ...formState,
            [ name ]: value
        });
    }

    const settogleIcon = value => setTogleIcon(value);
    const setidPase    = value => setIdPase(value);
    const setmsjPase   = value => setMsjPase(value);
    const setmsjOkPase = value => setMsjOkPase(value);

    const setloading  = value => setLoading(value);
    const onResetForm = () => setFormState( initialForm );

    return {
        ...formState,

        settogleIcon,
        togleIcon,

        setidPase,
        idPase,

        setmsjPase,
        msjPase,

        setmsjOkPase,
        msjOkPase,

        setloading,
        loading,

        setFormState,    

        onInputChange,
        onResetForm
    }
}